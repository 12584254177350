import axios from "axios";
import { formatoHtml } from "./misFunciones";

export default {
  namespaced: true,
  name: "login",
  state: {
    stDB: "", // nombre de la base de datos
    stImagenLogo: "",
    stImagenLogin: "",

    stUsuarioLogeado: false, // indica si el usuario esta logeado
    stUsuarioNombre: "", // nombre del logueado
    stUsuarioId: 0, // id unico del usuario (id_estudio o Id_Unidad, etc)
    stUsuarioTipo: "", // C contribuyente E estudio
    stToken: "", // guarda el token del logeado

    cfgSolicitarIdTributo: false, // campos que se pinde en el login
    cfgSolicitarIdPadron: false,
    cfgSolicitarIdUnidad: false,
    cfgMostrarUnSoloPadron: false,
    cfgPrimerIngresoListo: false,
    cfgMostrarFaltas: false,

    cfgSuscripcion: "",

    cfgEntidadCodigo: "",
    cfgEntidadNombre: "",
    cfgEntidadDireccion: "",
    cfgEntidadTelefono: "",
    cfgMensajeDeudaDesde: "",
    cfgMensajeDeudaAnterior: "",
    cfgMensajeIngreso: "",
    cfgMantenimientoMensaje: "",
    cfgMensajeDrei: "",
    cfgPagoElectronico: false,
    cfgPlusPagos: 0,
    cfgPlusPagosURL: "",
    cfgCajero24: 0,
    cfgCajero24URL: "",
    cfgLiquidar: false,
    cfgMantenimiento: false,
    cfgTributos: [], // son los tributos que tienen hablitado la comuna
    usaTotalApp: 0,
    usaBoletaWeb: false,
    usaTurnos: false,
    usaExpedientes: false,
    usaSueldos: false,
    usaDigesto: false,
    usaAdministrativo: false,
    usaReclamos: false,
    cfgReclamosImagen: false,
  },

  action: {
    alerta2() {
      this.commit("stOverAlerta", {
        ver: true,
        texto: "hola",
        tipo: "error",
      });
    },
  },

  mutations: {
    stLogin(state, { token, usuarioNombre, usuarioId, usuarioTipo }) {
      state.stToken = token;

      // cambio el headers del axios para que mande el muevo token
      axios.defaults.headers.common["Token"] = token;
      if (token > "") {
        state.stUsuarioLogeado = true;
        state.stUsuarioId = usuarioId;
        state.stUsuarioNombre = usuarioNombre.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
        state.stUsuarioTipo = usuarioTipo;
      } else {
        state.stUsuarioLogeado = false;
        state.stUsuarioId = -1;
        state.stUsuarioNombre = "";
        state.stUsuarioTipo = usuarioTipo;
      }
      //console.log(state.stToken = token, state.stUsuarioLogeado, state.stUsuarioId, state.stUsuarioNombre)
    },

    async st_primerIngreso(state, rootState) {
      // obtiene los parametros de conexion,

      // si viene comuna.boletaweb.com.ar
      state.stDB = location.host.split(".")[0];
      if ((state.stDB == 'www') || (state.stDB == 'WWW'))
         state.stDB = location.host.split(".")[1];
      // si viene boletaweb.com.ar\#comuna
      if (state.stDB === "") state.stDB = location.hash.replace("#", "");
      if (state.stDB === "localhost:8080" || state.stDB === "") state.stDB = "hasenkamp";

      axios.defaults.baseURL += "/" + state.stDB;

      //state.stImagenLogo = '/logos/' + state.stDB + '/logo.png'
      //state.stImagenLogin = '/logos/' + state.stDB + '/logo_muni.png'
      //console.log(axios.defaults.baseURL);
      try {
        this.commit("stOverCargando", {
          ver: true,
        });
        let res = await axios.post("primerIngreso");
        if (res.data.resultado) {
          state.cfgTributos = res.data.tributos;
          state.stImagenLogo = "data:image/jpeg;base64," + res.data.logoSistema;
          state.stImagenLogin = "data:image/jpeg;base64," + res.data.logoLogin;
          /* res.data.cfg.forEach(element => { */
          for (const element of res.data.cfg) {
            switch (element["ITEM"]) {
              case "NOMBRE_ENTIDAD":
                state.cfgEntidadNombre = element["VALOR_TEXTO"];
                document.title = "Gobierno Digital " + element["VALOR_TEXTO"];
                break;
              case "CODIGO_ENTIDAD":
                state.cfgEntidadCodigo = element["VALOR_TEXTO"];
                break;
              case "DIRECCION_ENTIDAD":
                state.cfgEntidadDireccion = formatoHtml(element["VALOR_TEXTO"]);
                break;
              case "TELEFONO_ENTIDAD":
                state.cfgEntidadTelefono = formatoHtml(element["VALOR_TEXTO"]);
                break;
              case "MENSAJE_DEUDA_DESDE":
                state.cfgMensajeDeudaDesde = formatoHtml(element["VALOR_TEXTO"]);
                break;
              case "MENSAJE_DEUDA_ANTERIOR":
                state.cfgMensajeDeudaAnterior = formatoHtml(element["VALOR_TEXTO"]);
                break;
              case "MENSAJE_INGRESO":
                state.cfgMensajeIngreso = formatoHtml(element["VALOR_TEXTO"]);
                break;
              case "MENSAJE_DREI":
                state.cfgMensajeDrei = formatoHtml(element["VALOR_TEXTO"]);
                break;
              case "MANTENIMIENTO":
                if (element["VALOR_TEXTO"] === "S") state.cfgMantenimiento = true;
                break;
              case "MANTENIMIENTO_MENSAJE":
                state.cfgMantenimientoMensaje = formatoHtml(element["VALOR_TEXTO"]);
                break;
              case "SOLICITAR_IDTRIBUTO":
                if (element["VALOR_TEXTO"] === "S") state.cfgSolicitarIdTributo = true;
                break;
              case "SOLICITAR_IDPADRON":
                if (element["VALOR_TEXTO"] === "S") state.cfgSolicitarIdPadron = true;
                break;
              case "SOLICITAR_IDUNIDAD":
                if (element["VALOR_TEXTO"] === "S") state.cfgSolicitarIdUnidad = true;
                break;
              case "MOSTRAR_FALTAS":
                if (element["VALOR_TEXTO"] === "S") state.cfgMostrarFaltas = true;
                break;
              case "MOSTRAR_SOLO_UN_PADRON":
                if (element["VALOR_TEXTO"] === "S") {
                  state.cfgMostrarUnSoloPadron = true;
                  state.cfgSolicitarIdTributo = true; // los paso a true por las dudas ya que los necesito si o si
                  state.cfgSolicitarIdPadron = true;
                }
                break;
              case "SUSCRIPCION":
                if (element["VALOR_TEXTO"] > "") state.cfgSuscripcion = element["VALOR_TEXTO"];
                break;
              case "CAJERO_24":
                if (element["VALOR_ENTERO"] > 0) {
                  state.cfgCajero24 = element["VALOR_ENTERO"];
                  state.cfgCajero24URL = element["VALOR_TEXTO"];
                  state.cfgPagoElectronico = true;
                }
                break;
              case "PLUS_PAGO_URL":
                if (element["VALOR_ENTERO"] > 0) {
                  state.cfgPlusPagos = element["VALOR_ENTERO"];
                  state.cfgPlusPagosURL = element["VALOR_TEXTO"];
                  state.cfgPagoElectronico = true;
                }
                break;
              case "LIQUIDAR":
                if (element["VALOR_TEXTO"] === "S") state.cfgLiquidar = true;
                break;

              case "BOLETAWEB":
                if (element["VALOR_TEXTO"] === "S") {
                  state.usaBoletaWeb = true;
                  state.usaTotalApp++;
                }
                break;
              case "TURNOS":
                if (element["VALOR_TEXTO"] === "S") {
                  state.usaTurnos = true;
                  state.usaTotalApp++;
                }
                break;
              case "EXPEDIENTES":
                if (element["VALOR_TEXTO"] === "S") {
                  state.usaExpedientes = true;
                  state.usaTotalApp++;
                }
                break;
              case "SUELDOS":
                if (element["VALOR_TEXTO"] === "S") {
                  state.usaSueldos = true;
                  state.usaTotalApp++;
                }
                break;
              case "DIGESTO":
                if (element["VALOR_TEXTO"] === "S") {
                  state.usaDigesto = true;
                  state.usaTotalApp++;
                }
                break;
              case "ADMINISTRATIVO":
                if (element["VALOR_TEXTO"] === "S") {
                  state.usaAdministrativo = true;
                  state.usaTotalApp++;
                }
                break;
              case "RECLAMOS":
                if (element["VALOR_TEXTO"] === "S") {
                  state.usaReclamos = true;
                  state.usaTotalApp++;
                }
                break;
              case "SOLICITAR_IMAGEN_REC":
                if (element["VALOR_TEXTO"] === "S") state.cfgReclamosImagen = true;
              default:
                break;
            }
          }

          state.cfgPrimerIngresoListo = true;
        } else {
          // si no devuelve ok mostrar error de algun tipo, comuna que no existe, etc
          this.commit("stOverAlerta", {
            ver: true,
            texto: "ERROR - Los parámetros no son correctos\n" + res.data.mensaje,
            tipo: "error",
          });
         // console.log(res);
          console.log(axios.defaults.baseURL);
        }
      } catch (error) {
        // error del servidor directamente mostrar alerta furiosa
        this.commit("stOverAlerta", {
          ver: true,
          texto: "EL SERVIDOR NO RESPONDE\n" + error,
          tipo: "error",
        });
      } finally {
        this.commit("stOverCargando", {
          ver: false,
        });
      }
    },
  },
};

// para llamar a funciones del index
//st_primerIngreso1(){
//console.log( this );
//this.commit('stOverAlerta', {ver:true, texto:'hola', tipo:'error'})  // commit para los metodos
//this.dispatch('alerta');   // dispatch para las acciones
//context.commit('alerta2')
//  },
